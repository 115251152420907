<template>
  <div>
    <b-form @submit.prevent="getOTP">
      <div class="container d-flex align-items-start flex-column vh-100" fluid="lg">
        <div class="row mx-auto">
          <div class="col-12 pt-4">
            <h6 class="text-center">{{ $t('forget_pincode') }}</h6>
          </div>

          <div class="col-12 pt-4">
            <h6>
              {{ $t('phone_number.label') }}
              <span v-if="!$v.phone.required || !$v.phone.minLength || !$v.phone.maxLength" class="text-danger">*</span>
            </h6>

            <b-form-input
              v-model.trim="$v.phone.$model"
              v-mask="'##########'"
              size="sm"
              :class="{ error: $v.phone.$error }"
              :placeholder="$t('phone_number.placeholder')"
              type="tel"
              autocomplete="off"
            ></b-form-input>
            <!-- <div class="small text-light">{{ $t("phone_number.example") }}</div> -->
          </div>
        </div>

        <div class="row mt-auto mx-auto">
          <div class="col-12 mb-4">
            <b-button type="submit" pill variant="primary px-4">{{ $t('reset_pincode') }}</b-button>
          </div>
        </div>

        <loading :is-busy="isBusy"></loading>
      </div>
    </b-form>
  </div>
</template>

<script>
import AccountService from '@/api/service/AccountService'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      phone: null,
      isBusy: false,
    }
  },
  methods: {
    async getOTP() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        try {
          this.isBusy = true
          const res = await AccountService.getOTP({
            phone_number: this.phone,
            forget_password: true,
          })

          this.isBusy = false

          if (res.status == 200) {
            if (process.env.VUE_APP_ENVIROMENT === 'local' || process.env.VUE_APP_ENVIROMENT === 'staging') {
              this.$store.dispatch('register/saveOtpCode', {
                otpCode: res.data.data,
              })
            }

            this.$store.dispatch('register/saveRegisterInfo', {
              payload: {
                phone: this.phone,
              },
            })
            this.$router.push({ name: 'forget-password-otp' })
          }
        } catch (err) {
          this.isBusy = false
          this.showAlert(err)
        }
      }
    },
  },
  validations: {
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
  },
}
</script>

<style></style>
